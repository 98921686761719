@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat|Roboto+mono|Raleway");
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  padding: var(— space) var(— space) 0;
  width: 100%;
  flex: 1 0 auto;
}

.App-content:after {
  content: ‘ ’;
  display: block;
  margin-top: var(— space);
  height: 0;
  visibility: hidden;
}

@media (max-width: 1000px) {
  div#insideStyles {
    margin-left: 30px;
    margin-top: 200px;
    font-size: 25px;
    color: #fff;
  }
}
@media (max-width: 411px) {
  div#insideStyles {
    margin-left: 30px;
    margin-top: 200px;
    font-size: 25px;
    color: #fff;
  }

  p#jobTitle {
    font-size: 20px;
  }

  div#mobileContent {
    height: 200px;
  }

  img#petImg {
    width: 20em;
    height: auto;
  }

  h1 {
    font-size: 20px;
  }

  #contact {
    font-size: 14px;
  }

  #projectTitleContain {
    margin-right: 10px;
  }

  h4 {
    padding-left: 10px !important;
    font-size: 20px !important;
  }

  .react-parallax-bgimage {
    left: 0% !important;
  }

  aside.ScrollUpButton__Container {
    display: none !important;
  }
}
.react-parallax-bgimage {
  top: 10% !important;
}

#NavHeader {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #0c264f;
  width: 100%;
}

#leftJob {
  font-family: "Roboto Mono", monospace;
  font-size: 25px;
  color: #000;
  text-align: right;
}

#rightJob {
  font-family: "Roboto Mono", monospace;
  font-size: 25px;
  color: #000;
  text-align: start;
}

#first {
  font-family: "Raleway", sans-serif;
  font-size: 65px;
  color: #D3D6D9;
}

#last {
  font-family: "Raleway", sans-serif;
  font-size: 65px;
  color: #D3D6D9;
}

#left {
  flex-grow: 0;
}

#right {
  flex-grow: 0;
}

#anchor {
  height: 130px;
  width: 150px;
}

#NavFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 90px;
  background-color: #4893b5;
  font-family: "Montserrat", sans-serif !important;
  color: #fff;
}

#subFooter {
  font-size: 15px;
}

#aboutTitle {
  font-family: "Montserrat", sans-serif !important;
}

.logo {
  width: 30px;
  margin: 10px;
}

#coffee {
  color: #D3D6D9;
}

#skills {
  margin-bottom: 20px;
}

#About {
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 50px;
  margin-top: 10px;
  text-align: justify;
  text-justify: inter-word;
}

#aboutStyle {
  margin-top: 30px;
  border-bottom: 3px solid;
}

#Projects {
  margin-top: 50px;
  margin-bottom: 50px;
  border-bottom: 3px solid;
  font-family: "Montserrat", sans-serif !important;
}

#projectTitleContain {
  display: flex;
  justify-content: flex-end;
}

#ProjectTitle {
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 30px;
}

#echo1 {
  height: 250px;
  width: 150px;
}

#echo2 {
  height: 248px;
  width: 143px;
}

#echoImgContain {
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif !important;
}

#echoRow {
  border-bottom: 5px inset;
  margin-top: 50px;
  padding-bottom: 40px;
}

#petContain {
  margin-top: 50px;
  padding-bottom: 50px;
}

#petImg {
  height: 350px;
  width: 500px;
}

#contactRow {
  margin: 20px;
  font-family: "Montserrat", sans-serif !important;
}

#edu #btn {
  margin-top: 20px;
}

#insideStyles {
  color: #4893b5;
  font-size: 80px;
  padding: 20px;
  position: absolute;
  top: 40%;
  left: 25%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif !important;
}

#jobTitle {
  color: #fff;
  font-size: 30px;
  font-family: "Roboto Mono", monospace;
}

p {
  text-align: justify;
  text-justify: inter-word;
}